import React from 'react'

const color = {
  light: '#282828',
  dark: '#fff'
}

export default ({ colorMode, className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 380.92 85.04" className={ className }>
        <path d="M119,13.28A15.23,15.23,0,0,0,108.21,2.54C98.73,0,60.74,0,60.74,0s-38,0-47.46,2.54A15.21,15.21,0,0,0,2.54,13.28C0,22.75,0,42.52,0,42.52S0,62.29,2.54,71.76A15.21,15.21,0,0,0,13.28,82.5C22.75,85,60.74,85,60.74,85s38,0,47.47-2.54A15.23,15.23,0,0,0,119,71.76c2.53-9.47,2.53-29.24,2.53-29.24S121.48,22.75,119,13.28Z" style={{ fill: 'red' }}/>
        <polygon points="48.59 60.74 80.16 42.52 48.59 24.3 48.59 60.74" style={{ fill: '#fff' }}/>
        <path d="M176.34,77.36a13.2,13.2,0,0,1-5.13-7.55,49.68,49.68,0,0,1-1.52-13.64V48.43a44.8,44.8,0,0,1,1.74-13.81,13.78,13.78,0,0,1,5.43-7.6,17.59,17.59,0,0,1,9.68-2.39q5.91,0,9.47,2.43a14,14,0,0,1,5.22,7.6,46.66,46.66,0,0,1,1.65,13.77v7.74a47.48,47.48,0,0,1-1.61,13.68,13.45,13.45,0,0,1-5.21,7.56q-3.62,2.39-9.78,2.39Q179.94,79.8,176.34,77.36ZM189.8,69q1-2.59,1-8.51V43.92c0-3.83-.33-6.62-1-8.39a3.59,3.59,0,0,0-3.52-2.65,3.54,3.54,0,0,0-3.43,2.65c-.67,1.77-1,4.56-1,8.39V60.51q0,5.91,1,8.51a3.48,3.48,0,0,0,3.47,2.61A3.57,3.57,0,0,0,189.8,69Z" style={{ fill: color[colorMode] }}/>
        <path d="M360.86,56.29V59a71.88,71.88,0,0,0,.3,7.68,7.27,7.27,0,0,0,1.26,3.74,3.59,3.59,0,0,0,2.95,1.17,3.72,3.72,0,0,0,3.7-2.08q1-2.09,1.08-7l10.34.61a15.67,15.67,0,0,1,.09,1.91q0,7.38-4,11c-2.7,2.43-6.5,3.65-11.43,3.65-5.9,0-10.05-1.85-12.42-5.56s-3.56-9.44-3.56-17.2v-9.3q0-12,3.69-17.5t12.64-5.52q6.17,0,9.47,2.26a12.11,12.11,0,0,1,4.65,7,50.85,50.85,0,0,1,1.34,13.21v9.12Zm1.52-22.41a7.26,7.26,0,0,0-1.22,3.69,73.27,73.27,0,0,0-.3,7.78v3.82h8.77V45.35a64.93,64.93,0,0,0-.35-7.78A7.44,7.44,0,0,0,368,33.83a3.48,3.48,0,0,0-2.82-1.08A3.41,3.41,0,0,0,362.38,33.88Z" style={{ fill: color[colorMode] }}/>
        <path d="M147.14,55.3,133.5,6h11.9l4.78,22.32q1.83,8.27,2.7,14.08h.34q.61-4.17,2.7-14l5-22.41h11.9L159,55.3V78.93H147.14Z" style={{ fill: color[colorMode] }}/>
        <path d="M241.59,25.67V78.93H232.2l-1-6.52h-.26q-3.83,7.38-11.47,7.39-5.3,0-7.82-3.48c-1.68-2.31-2.52-5.94-2.52-10.86V25.67h12v39.1a12,12,0,0,0,.78,5.08,2.72,2.72,0,0,0,2.61,1.52,5.33,5.33,0,0,0,3-1A5.93,5.93,0,0,0,229.6,68V25.67Z" style={{ fill: color[colorMode] }}/>
        <path d="M303.08,25.67V78.93H293.7l-1-6.52h-.26q-3.82,7.38-11.47,7.39c-3.54,0-6.14-1.16-7.82-3.48s-2.52-5.94-2.52-10.86V25.67h12v39.1a12,12,0,0,0,.78,5.08A2.71,2.71,0,0,0,286,71.37a5.28,5.28,0,0,0,3-1A5.79,5.79,0,0,0,291.09,68V25.67Z" style={{ fill: color[colorMode] }}/>
        <path d="M274.16,15.68h-11.9V78.93H250.53V15.68h-11.9V6h35.53Z" style={{ fill: color[colorMode] }}/>
        <path d="M342.76,34.18c-.72-3.35-1.9-5.79-3.52-7.29a9.43,9.43,0,0,0-6.69-2.26,11.08,11.08,0,0,0-6.17,1.87A12.7,12.7,0,0,0,322,31.41h-.08V3.34H310.31V78.93h9.91l1.21-5h.26a10.22,10.22,0,0,0,4.17,4.25A12.31,12.31,0,0,0,332,79.71a9.45,9.45,0,0,0,9-5.6q2.86-5.61,2.87-17.51V48.17A70.3,70.3,0,0,0,342.76,34.18Zm-11,21.73a67,67,0,0,1-.48,9.12,9.88,9.88,0,0,1-1.6,4.69,3.72,3.72,0,0,1-3,1.39,5.6,5.6,0,0,1-2.74-.69,5.27,5.27,0,0,1-2-2.09V38.1A7.51,7.51,0,0,1,324,34.54a4.62,4.62,0,0,1,3.22-1.39A3.23,3.23,0,0,1,330,34.58a10.91,10.91,0,0,1,1.39,4.82,90.13,90.13,0,0,1,.39,9.64Z" style={{ fill: color[colorMode] }}/>
    </svg>
)
