import React from 'react'

export default ({ className }) => (
    <svg viewBox="0 0 512 512.00038" xmlns="http://www.w3.org/2000/svg" className={className}>
        <radialGradient id="a" cx="104.9571229418" cy="447.4474330468" gradientUnits="userSpaceOnUse" r="564.64588968">
            <stop offset="0" stopColor="#fae100"/>
            <stop offset=".0544" stopColor="#fadc04"/>
            <stop offset=".1167" stopColor="#fbce0e"/>
            <stop offset=".1829" stopColor="#fcb720"/>
            <stop offset=".2514" stopColor="#fe9838"/>
            <stop offset=".3054" stopColor="#ff7950"/>
            <stop offset=".4922" stopColor="#ff1c74"/>
            <stop offset="1" stopColor="#6c1cd1"/>
        </radialGradient>
        <linearGradient id="b" gradientUnits="userSpaceOnUse" x1="196.3726539866" x2="-671.0159540134" y1="222.4596783332" y2="-265.4464136668">
            <stop offset="0" stopColor="#a1b5d8" stopOpacity="0"/>
            <stop offset=".3094" stopColor="#90a2bd" stopOpacity=".309804"/>
            <stop offset=".7554" stopColor="#7c8b9c" stopOpacity=".756863"/>
            <stop offset="1" stopColor="#748290"/>
        </linearGradient>
        <linearGradient id="c" gradientUnits="userSpaceOnUse" x1="256.0003" x2="256.0003" y1="451.9660859688" y2="531.773969673">
            <stop offset="0" stopColor="#fae100" stopOpacity="0"/>
            <stop offset=".3068" stopColor="#fca800" stopOpacity=".305882"/>
            <stop offset=".6275" stopColor="#fe7300" stopOpacity=".627451"/>
            <stop offset=".8685" stopColor="#ff5200" stopOpacity=".866667"/>
            <stop offset="1" stopColor="#ff4500"/>
        </linearGradient>
        <linearGradient id="d">
            <stop offset="0" stopColor="#833ab4" stopOpacity="0"/>
            <stop offset="1" stopColor="#833ab4"/>
        </linearGradient>
        <linearGradient id="e" gradientUnits="userSpaceOnUse" x1="226.8724066998" x2="100.1606848024" y1="226.147987369" y2="99.4361650794"/>
        <linearGradient id="f" gradientUnits="userSpaceOnUse" x1="350.899540777" x2="287.6555669352" y1="468.287448276" y2="170.1375727138"/>
        <linearGradient id="g" gradientUnits="userSpaceOnUse" x1="374.965057" x2="120.9410670648" y1="374.9649673922" y2="120.9408770648"/>
        <linearGradient id="h" gradientUnits="userSpaceOnUse" x1="393.806665096" x2="309.8058007666" y1="221.2631037014" y2="137.2623397642"/>
        <linearGradient id="i" gradientUnits="userSpaceOnUse" x1="357.6582448576" x2="150.5426107646" y1="155.0495285836" y2="362.1651626766">
            <stop offset="0" stopColor="#833ab4"/>
            <stop offset=".0922" stopColor="#9c3495"/>
            <stop offset=".2927" stopColor="#dc2546"/>
            <stop offset=".392" stopColor="#fd1d1d"/>
            <stop offset=".5589" stopColor="#fc6831"/>
            <stop offset=".6887" stopColor="#fc9b40"/>
            <stop offset=".7521" stopColor="#fcaf45"/>
            <stop offset=".7806" stopColor="#fdb750"/>
            <stop offset=".8656" stopColor="#fecb6a"/>
            <stop offset=".9415" stopColor="#ffd87a"/>
            <stop offset="1" stopColor="#ffdc80"/>
        </linearGradient>
        <path d="m503.234375 91.578125c-4.660156-43.664063-39.144531-78.15625-82.8125-82.8125-109.507813-11.6875-219.335937-11.6875-328.839844 0-43.667969 4.660156-78.15625 39.148437-82.816406 82.8125-11.6875 109.503906-11.6875 219.335937 0 328.839844 4.660156 43.667969 39.148437 78.15625 82.8125 82.816406 109.503906 11.6875 219.335937 11.6875 328.84375 0 43.667969-4.660156 78.152344-39.148437 82.8125-82.816406 11.6875-109.503907 11.6875-219.332031 0-328.839844zm0 0" fill="url(#a)"/>
        <path d="m475.386719 110.097656c-4.132813-38.746094-34.734375-69.351562-73.484375-73.488281-97.171875-10.367187-194.632813-10.367187-291.804688 0-38.746094 4.136719-69.351562 34.742187-73.488281 73.488281-10.367187 97.171875-10.367187 194.632813 0 291.800782 4.136719 38.75 34.742187 69.355468 73.488281 73.488281 97.171875 10.371093 194.632813 10.371093 291.800782 0 38.75-4.132813 69.355468-34.738281 73.488281-73.488281 10.371093-97.167969 10.371093-194.628907 0-291.800782zm0 0" fill="url(#b)"/>
        <path d="m7.671875 409.804688c.351563 3.539062.714844 7.078124 1.09375 10.617187 4.660156 43.664063 39.148437 78.152344 82.816406 82.8125 109.503907 11.6875 219.335938 11.6875 328.839844 0 43.667969-4.660156 78.152344-39.148437 82.8125-82.8125.378906-3.539063.742187-7.078125 1.097656-10.617187zm0 0" fill="url(#c)"/>
        <path d="m503.234375 420.417969c6.28125-58.839844 9.179687-117.773438 8.710937-176.699219l-117.03125-117.03125c-14.621093-16.691406-35.976562-27.109375-61.070312-28.011719-51.605469-1.859375-103.375-1.765625-154.988281.007813-42.867188 1.476562-72.84375 30.289062-80.53125 72.636718-1.355469 7.476563-2.167969 15.050782-3.234375 22.582032v124.148437c.589844 4.023438 1.457031 8.027344 1.726562 12.074219 1.71875 25.757812 12.304688 47.820312 29.253906 62.746094l119.09375 119.089844c58.445313.410156 116.894532-2.496094 175.257813-8.726563 43.667969-4.660156 78.152344-39.148437 82.8125-82.816406zm0 0" fill="url(#e)"/>
        <path d="m503.234375 420.421875c-4.65625 43.660156-39.152344 78.15625-82.8125 82.8125-58.355469 6.226563-116.816406 9.136719-175.253906 8.726563l-118.914063-118.914063c13.785156 12.066406 31.753906 19.414063 52.605469 20.199219 51.601563 1.9375 103.382813 1.886718 154.984375.027344 46.671875-1.6875 80.445312-36.230469 81.902344-82.902344 1.554687-49.554688 1.554687-99.238282 0-148.792969-.664063-21.53125-8.222656-40.476563-20.753906-54.8125l116.957031 116.957031c.460937 58.917969-2.4375 117.859375-8.714844 176.699219zm0 0" fill="url(#f)"/>
        <path d="m316.414062 200.558594c-14.992187-16.324219-36.503906-26.566406-60.414062-26.566406-45.289062 0-82.007812 36.71875-82.007812 82.007812 0 23.910156 10.242187 45.421875 26.566406 60.414062l189.738281 189.738282c10.042969-.875 20.085937-1.847656 30.121094-2.917969 43.667969-4.660156 78.15625-39.148437 82.816406-82.816406 1.070313-10.035157 2.042969-20.078125 2.917969-30.121094zm0 0" fill="url(#g)"/>
        <path d="m511.007812 311.152344-152.703124-152.699219c-3.5625-4.675781-9.175782-7.710937-15.507813-7.710937-10.773437 0-19.511719 8.734374-19.511719 19.511718 0 6.332032 3.035156 11.945313 7.710938 15.507813l177.28125 177.285156c1.203125-17.292969 2.113281-34.59375 2.730468-51.894531zm0 0" fill="url(#h)"/>
        <path d="m95.089844 193.902344c1.066406-7.53125 1.878906-15.105469 3.234375-22.582032 7.683593-42.347656 37.664062-71.160156 80.53125-72.636718 51.613281-1.773438 103.382812-1.867188 154.988281-.007813 46.65625 1.679688 80.445312 36.226563 81.902344 82.898438 1.550781 49.558593 1.550781 99.238281 0 148.796875-1.457032 46.671875-35.234375 81.214844-81.898438 82.898437-51.605468 1.863281-103.386718 1.910157-154.988281-.027343-46.664063-1.753907-78.921875-36.378907-82.042969-83.121094-.269531-4.042969-1.136718-8.050782-1.726562-12.074219 0-41.382813 0-82.765625 0-124.144531zm160.953125 191.707031c23.617187 0 47.257812.707031 70.84375-.164063 36.980469-1.371093 59.726562-23.441406 60.589843-60.386718 1.070313-46.035156 1.070313-92.132813 0-138.171875-.863281-36.9375-23.625-59.523438-60.589843-60.308594-46.917969-.992187-93.886719-.984375-140.804688 0-36.683593.769531-59.496093 22.898437-60.492187 59.429687-1.265625 46.617188-1.265625 93.316407 0 139.933594.996094 36.527344 23.808594 58.144532 60.496094 59.503906 23.289062.867188 46.636718.164063 69.957031.164063zm0 0" fill="url(#i)"/>
        <g fill="#fff">
            <path d="m95.089844 193.902344c1.066406-7.53125 1.878906-15.105469 3.234375-22.582032 7.683593-42.347656 37.664062-71.160156 80.53125-72.636718 51.613281-1.773438 103.382812-1.867188 154.988281-.007813 46.65625 1.679688 80.445312 36.226563 81.902344 82.898438 1.550781 49.558593 1.550781 99.238281 0 148.796875-1.457032 46.671875-35.234375 81.214844-81.898438 82.898437-51.605468 1.863281-103.386718 1.910157-154.988281-.027343-46.664063-1.753907-78.921875-36.378907-82.042969-83.121094-.269531-4.042969-1.136718-8.050782-1.726562-12.074219 0-41.382813 0-82.765625 0-124.144531zm160.953125 191.707031c23.617187 0 47.257812.707031 70.84375-.164063 36.980469-1.371093 59.726562-23.441406 60.589843-60.386718 1.070313-46.035156 1.070313-92.132813 0-138.171875-.863281-36.9375-23.625-59.523438-60.589843-60.308594-46.917969-.992187-93.886719-.984375-140.804688 0-36.683593.769531-59.496093 22.898437-60.492187 59.429687-1.265625 46.617188-1.265625 93.316407 0 139.933594.996094 36.527344 23.808594 58.144532 60.496094 59.503906 23.289062.867188 46.636718.164063 69.957031.164063zm0 0"/>
            <path d="m256 173.996094c-45.289062 0-82.007812 36.714844-82.007812 82.003906 0 45.292969 36.71875 82.007812 82.007812 82.007812 45.292969 0 82.007812-36.714843 82.007812-82.007812 0-45.289062-36.714843-82.003906-82.007812-82.003906zm0 135.777344c-29.699219 0-53.773438-24.074219-53.773438-53.773438s24.074219-53.773438 53.773438-53.773438 53.773438 24.074219 53.773438 53.773438-24.074219 53.773438-53.773438 53.773438zm0 0"/>
            <path d="m362.304688 170.253906c0 10.773438-8.734376 19.507813-19.507813 19.507813s-19.511719-8.734375-19.511719-19.507813c0-10.777344 8.738282-19.511718 19.511719-19.511718s19.507813 8.734374 19.507813 19.511718zm0 0"/>
        </g>
    </svg>
)
